<template>
  <v-container>
    <v-card outlined class="mx-auto" width="750px" style="border-color:transparent;">
      <v-layout wrap>
          <v-select dense outlined  rounded class="ma-1" style="height: 40px; width: 220px;" 
            id="farm" 
            ref="farm" 
            label="농장/사업장✽" 
            v-model="farm"
            :items="farmItems"
            :menu-props="{ top: false, offsetY: true }"
            no-data-text="자료(권한)이 없습니다."
            item-text="name"
            return-object
            @change="changeFarm()"
            @focus="comboFarms()"
            clearable
          />
          <v-select dense outlined rounded class="ma-1" style="height: 45px; width: 220px;"
              id="building" 
              ref="building" 
              label="축사/건물/구역✽" 
              v-model="building"
              :items="buildingItems"
              :menu-props="{ top: false, offsetY: true }"
              no-data-text="자료(권한)이 없습니다."
              item-text="name"
              return-object
              @change="changeBuilding()"
              @focus="comboBuildings()"
              clearable
            />
          <v-btn class="elevation-5 ma-1 rounded-pill normal-btn" style="height: 40px; width: 100px"
            id="refreshList" 
            :loading="loading" 
            ref="refreshList" 
            @click="refreshList()">
            <img src="@/assets/icon_refresh.svg" alt="새로고침" />
            새로고침
          </v-btn>
        </v-layout>
      </v-card>

      <v-card class="mt-3 mx-auto" width="750px" tile flat outlined>
        <v-tabs
          v-model="tab"
          cycle
          background-color="#3a4f3f"
          next-icon="mdi-arrow-right-bold-box-outline"
          prev-icon="mdi-arrow-left-bold-box-outline"
          show-arrows
          dark
        >
          <v-tab
            v-for="room in items"
            :key="room.room_cd"
            style="font-size:19px;"
          >
            {{ room.room_name }}
          </v-tab>
        </v-tabs>

        <v-tabs-items v-model="tab">
          <v-tab-item
            v-for="room in items"
            :key="room.room_cd"
          >
            <v-card flat class="mt-3 mb-5">
                <div id="textArea">
                  <div class="info_sensornode">
                    <div class="text_name">
                      <v-icon color=#3a4f3f size="30">
                        mdi-flash-off
                      </v-icon>
                      센서노드
                    </div>
                    <div class="sensor_on_off" :class="{gray: room.checkTime==='-'}"> {{ room.checkTime==='-'?'꺼짐':'켜짐' }}
                    </div>
                  </div>

                  <div class="info_sensor">
                    <div class="text_name">
                      <v-icon color=#3a4f3f size="30">
                        mdi-thermometer
                      </v-icon>
                      온도
                    </div>
                    <div class="text_number" > {{ room.temp }}
                      <span class="text_number_unit"> ℃ </span>
                    </div>
                  </div>

                  <div class="info_sensor">
                    <div class="text_name">
                      <v-icon color=#3a4f3f size="30">
                        mdi-water
                      </v-icon>
                      습도
                    </div>
                    <div class="text_number"> {{ room.hum }}
                      <span class="text_number_unit"> % </span>
                    </div>
                  </div>

                  <div class="info_sensor" v-if="room.co2!=='-'">
                    <div class="text_name">
                      <v-icon color=#3a4f3f size="30">
                        mdi-weather-cloudy
                      </v-icon>
                      이산화탄소
                    </div>
                    <div class="text_number"> {{ room.co2 }}
                      <span class="text_number_unit"> ppm </span>
                    </div>
                  </div>

                  <div class="info_sensor" v-if="room.nh3!=='-'">
                    <div class="text_name">
                      <v-icon color=#3a4f3f size="35">
                        mdi-waves
                      </v-icon>
                      암모니아
                    </div>
                    <div class="text_number"> {{ room.nh3 }}
                      <span class="text_number_unit"> ppm </span>
                    </div>
                  </div>


                  <div class="info_sensor" v-if="room.h2s!=='-'">
                    <div class="text_name">
                      <v-icon color=#3a4f3f size="30">
                        mdi-apple-keyboard-command
                      </v-icon>
                      황화수소
                    </div>
                    <div class="text_number"> {{ room.h2s }}
                      <span class="text_number_unit"> ppm </span>
                    </div>
                  </div>
                </div>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-card>

      <v-card class="mt-1 mx-auto" width="750px" style="border-color:transparent;" outlined>
        <v-row class="ml-1">
        <v-col md="2">
        <v-select dense  class="interval" 
            id="setInterval" 
            ref="setInterval"
            label="" 
            :items="setIntervalItems"
            item-text="descripton"
            return-object
            v-model="setInterval"
            @change="refreshList()"
          />  
        </v-col>
        <v-col class="mt-1 text-left">
          마다 새로고침
        </v-col>
        </v-row>
      </v-card>
  </v-container>
</template>

<script>
import Apis from '@/api/apis';
import VueCookies from "vue-cookies";
import dateUtil from "@/utils/custom/dateProperty.js";
var setTime = 0;

export default{
  name: "RoomTablet",

  async created() {
    Apis.pageCount({ //페이지뷰 카운터
      page_name: this.$route.name,
      url: this.$route.path,
      division: "pigro",
    },() => {  // 정상처리
      // console.log("pageCount 호출",res);
    }).catch( (err) => {  // 개별 API 오류 처리 예제
      console.log("pageCount 호출 오류",err);
    });

    this.isreload = true;
    await this.comboFarms();
    await this.comboBuildings();


    let cookie_interval = VueCookies.get("room_interval");
      if (cookie_interval !== "undefined" && cookie_interval) {
        // console.log("쿠 키interval",cookie_interval);
        this.setInterval = cookie_interval;
    } 

    let cookie_info = VueCookies.get("roomtablet_info");

    // let paramInfo = JSON.parse(sessionStorage.getItem("paramInfo"));
    // if (paramInfo) {
    //   console.log("파람",cookie_info);
    //   cookie_info = paramInfo;
    //   sessionStorage.removeItem("paramInfo");
    // }

    if (cookie_info) {
      // console.log("쿠키",cookie_info);
      this.farm = cookie_info.farm || {};
      this.building = cookie_info.building || {};
    } else {
      this.farm = this.farmItems[0];
    }
    this.refreshList();
    },

  
  data(){
    return{
      tab : null,
      farm : {},
      farmItems : [],
      building : {},
      buildingItems : [],

      items:[],

      loading: false,

      setInterval: {min: 30, descripton: "30 분"},
      setIntervalItems: [
        { min: 10, descripton: "10 분"},
        { min: 20, descripton: "20 분"},
        { min: 30, descripton: "30 분"},
        { min: 60, descripton: "1 시간"},
      ],

    }
  },
  destroyed() {
    clearTimeout(setTime);
    // console.log("clearTimeout",setTime);
  },


  methods: {
    refresh() {
      clearTimeout(setTime);
      this.refreshList();
    },

    refreshList() {
      this.$store.commit("resMessage","");
      if (!this.farm || !this.farm.code ) {
        alert("축사/건물/구역 또는 농장/사업장를 선택하세요");
        this.$refs.building.focus();
        return;
      }

      this.loading = true;
      
      let preItems = JSON.parse(sessionStorage.getItem("summaryData"));
      if (preItems) {
        this.items = preItems;
        sessionStorage.removeItem("summaryData");
        this.loading = false;
      } else {
        // console.log("리스트");
        Apis.monitoringSummary({
          farm_cd: this.farm && this.farm.code || "",
          building_cd: this.building && this.building.code || "",
          stand_dt: dateUtil.format(new Date(), "yyyy-MM-dd HH:mm:ss"), 
        } ,(res) => {  // 정상처리
          this.items = res.data;
          // console.log(res.data);
          if (this.msgFlag) this.$store.commit("resMessage",res.message);
          this.msgFlag = true;
          this.loading = false;
          let cookie_info = { farm: this.farm, building: this.building };
          let cookie_interval = this.setInterval;
          VueCookies.set("roomtablet_info" ,cookie_info,"30d");
          VueCookies.set("room_interval" ,cookie_interval,"30d");
          // console.log("path",this.$route.path);
          // setTimeout( () =>  this.getNowControllerValue(), 1000);
          this.$route.path == "/monitoring/roomtablet" ? setTime = setTimeout( () =>  this.refreshList(), this.setInterval.min * 60 * 1000) : "" ;
          // console.log("setTime",setTime);
        }).catch( (err) => {  // 개별 API 오류 처리 예제
          console.log("monitoringSummary 호출 오류",err);
          // alert("monitoringSummary 호출 오류" + err);
          this.$router.push("/");
          this.loading = false;
        })
      } 
    },

    async comboFarms() {
      await Apis.comboFarms({
        company_cd: "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.farmItems = [];
            for (let i in res.data) {
            this.farmItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              code: res.data[i].farm_cd,
              name: res.data[i].farm_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboFarms API 호출 오류",err)
            // alert(err);
        }
      ) 
    },
    async changeFarm() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      this.building = {};
      this.buildingItems = [];

      if(this.farm && this.farm.code){
        await this.comboBuildings();
        if(this.farm) this.building = this.buildingItems[0];
      }
    },
    async comboBuildings() {
      await Apis.comboBuildings({
        company_cd:this.company &&  this.company.code || "",
        farm_cd: this.farm && this.farm.code || "",
        stand_date: dateUtil.format(new Date(), "yyyy-MM-dd"), 
        },(res) => {  
          if (res.result) {
            this.buildingItems = [];
            for (let i in res.data) {
            this.buildingItems.push({
              company_cd: res.data[i].company_cd, 
              company_name: res.data[i].company_name, 
              farm_cd: res.data[i].farm_cd, 
              farm_name: res.data[i].farm_name, 
              code: res.data[i].building_cd,
              name: res.data[i].building_name,
              });
            }  
          } else {
            alert(res.message);
          }
        }).catch( (err) => {  // API 오류 처리
            console.log("comboBuildings API 호출 오류",err)
        }
      ) 
    },
    changeBuilding() {      // 하위콤보 선택시 상위 콤보 동시 세팅, 하위콤보 지우기
      if (this.building && this.building.farm_cd) {
        this.farm = {code: this.building.farm_cd, name: this.building.farm_name};
      }
    },
  },
}
</script>

<style lang="scss" scoped>
#textArea {
  margin: 0px 12px 0px 12px;
  resize: none;
}
.info_room {
  text-align: center;
  font-size: 30px !important;
  font-weight: bold !important;
  letter-spacing: -0.6px;
  height:80px;
  background-color: #6B7B6E;
  line-height: 80px;
  border-radius: 10px 10px 0 0;
  color: white;
}
.info_sensor{
  display: flex;
  justify-content: space-between;
  color:#3a4f3f;
  height:70px;
  line-height: 70px;
}
.info_sensornode{
  display: flex;
  justify-content: space-between;
  color:#3a4f3f;
  height:50px;
  line-height: 50px;
  // padding-left:10px;
  // padding-right:20px;
}

.sensor_on_off{
  font-size:30px;
  letter-spacing:-0.78px;
  background-color: #6B7B6E;
  color:white;
  width:80px;
  text-align: center;
  border-radius: 10px;
}

.gray{
  background-color: gray;
}

.text_name{
  font-size:30px;
  letter-spacing:-0.78px;
}
.text_number {
  font-size: 35px;
  font-weight:bold;
  letter-spacing: -1px;
}

.text_number_unit {
  height: 35px;
  font-size: 20px;
  margin-left: 5px;
  line-height: 42px;
}

.normal-btn{
  align-content: center !important;
  color:white !important;
  font-weight: bold !important;
  background-color: #3a4f3f !important;
}



</style>